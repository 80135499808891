//@import "bourbon/bourbon"

* {
	margin: 0;
	padding: 0; }

body {
	background-color: #fff;
	position: relative;
	font-size: 16px;
	overflow-x: hidden;
	font-family: 'Roboto', sans-serif; }

* {
	outline: none !important; }


.main_wrapper {
	background-image: url(../img/bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	padding: 100px 0;
	&__logo {
		display: inline-block;
		padding-bottom: 40px;
		width: 280px;
		height: 140px;
		a {
			display: block;
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%; } } }
	h1 {
		font-size: 30px;
		color: #EE1515;
		text-transform: uppercase;
		margin: 0;
		line-height: 1;
		letter-spacing: 5px; }
	.constructor {
		font-size: 18px;
		font-weight: 300;
		font-style: italic; }
	&__timer {
		width: 960px;
		margin: 0 auto;
		background-color: rgba(255,255,255, .5);
		height: 265px;
		margin-top: 60px;
		border-radius: 30px;
		#clockdiv {
			font-family: sans-serif;
			color: #000;
			display: inline-block;
			font-weight: 700;
			text-align: center;
			font-size: 80px;
			> div {
				padding: 35px 40px;
				border-radius: 3px;
				display: inline-block;
				position: relative;
				.doublepoint {
					position: absolute;
					top: 40px;
					right: -32px; }
				> span {
					padding: 15px;
					border-radius: 3px;
					display: inline-block; } }
			.smalltext {
				padding-top: 5px;
				font-size: 18px;
				font-weight: 700; } } }
	.follow {
		color: #fff;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 20px;
		margin-top: 80px;
		margin-bottom: 25px; }
	.follow2 {
		color: #fff;
		font-size: 18px; }
	&__social {
		list-style-type: none;
		display: inline-block;
		li {
			display: inline-block;
			margin-right: 12px;
			&:first-child {
				&:hover {
					a {
						color: #517296; } } }
			&:nth-child(2) {
				&:hover {
					a {
						color: #3A5795; } } }
			&:last-child {
				margin-right: 0;
				&:hover {
					a {
						color: #DD4A39; } } }
			a {
				color: #D9B479;
				display: inline-block;
				text-align: center;
				width: 38px;
				height: 38px;
				padding: 6px 8px 4px;
				border-radius: 50%;
				background-color: #fff;
				transition: color .3s ease;
				.fa {
					font-size: 20px;
					position: relative;
					top: 2px; } } } } }
