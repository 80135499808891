* {
	padding: 0;
	margin: 0;
	outline: none !important; }

body {
	position: relative;
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.75;
	color: #393939;
	font-size: 16px;
	overflow-x: hidden !important;
	min-width: 320px; }

a {
	color: #393939;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #393939; } }

ul {
	list-style: none; }

::-webkit-input-placeholder {
	color: #fff; }

:-moz-placeholder {
	color: #fff; }

::-moz-placeholder {
	color: #fff; }

:-ms-input-placeholder {
	color: #fff; }

// style start
.mobile_logo {
	display: none; }
.soc_buttons {
	display: inline-block;
	float: left;
	li {
		display: inline-block;
		float: left;
		transition: opacity .3s ease;
		&:hover {
			opacity: .7; }
		&:not(:last-child) {
			margin-right: 20px; }
		&:last-child {
			margin-right: 38px;
			i {
				position: relative;
				top: -1px; } }
		a {
			width: 26px;
			height: 26px;
			background-color: #bdbec0;
			border-radius: 50%;
			display: inline-block;
			text-align: center;
			i {
				font-size: 15px;
				color: #fff; } } } }

.main_header_top_info {
	margin-top: 16px;
	float: right;
	.language {
		display: inline-block;
		float: left;
		margin-top: 9px;
		margin-right: 35px;
		li {
			display: inline-block;
			float: left;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			color: #ef1c22;
			line-height: 9px;
			cursor: pointer;
			transition: opacity .3s ease;
			&:hover {
				opacity: .7; }
			&:first-child {
				border-right: 1px solid #393939;
				padding-right: 8px;
				margin-right: 8px; } } }
	.search_button {
		display: inline-block;
		float: left;
		margin-right: 35px;
		cursor: pointer; }
	.login {
		font-size: 13px;
		font-weight: 500; } }
.main_header_middle_info {
	margin-top: 20px;
	.logo {
		float: left;
		display: inline-block;
		width: 190px;
		img {
			width: 100%;
			height: 100%; } }
	.naigation, .navigation ul {
		position: relative;
		margin-left: 205px;
		li {
			display: inline-block;
			float: left;
			text-align: center;
			margin-top: 15px;
			&:nth-child(5) a {
				max-width: 125px; }
			&:nth-child(1) a {
				max-width: 67px; }
			&:not(:last-child) {
				border-right: 1px solid #dfdfdf; }
			a {
				font-size: 13px;
				font-weight: 500;
				text-transform: uppercase;
				line-height: 16px;
				padding: 12px 10px;
				padding-bottom: 9px;
				max-width: 105px;
				display: inline-block;
				border-bottom: 3px solid #fff;
				transition: border-bottom-color .3s ease;
				&:hover {
					border-bottom-color: #bc141b; } }
			.active {
				color: #fff;
				background-color: #ff1d24;
				border-bottom: 3px solid #bc141b; } } } }
.search_input {
	input {
		border: none;
		color: #fff;
		font-size: 15px;
		font-weight: 400;
		position: absolute;
		padding: 0;
		width: 0;
		z-index: 2;
		top: 31px;
		margin-left: 14px;
		background-color: #ef1c22; } }
.main_header_bottom_info {
	height: 685px;
	background: url("../img/index/header-image.png") no-repeat;
	background-size: 100% 100%;
	margin-top: 20px;
	h3 {
		color: #fff;
		font-size: 50px;
		font-weight: 500;
		text-transform: uppercase;
		margin-top: 78px;
		margin-bottom: 20px; }
	p {
		color: #fff;
		font-size: 23px;
		line-height: 1.3;
		width: 72%;
		font-weight: 300; }
	.select_wrapper {
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 13px rgba(0, 0, 0, 0.32);
		padding: 50px 0; }
	form {
		margin-top: 90px;
		text-align: center;
		.select2:not(:last-child) {
			margin-right: 30px; }
		select {
			display: none; }
		.select2-container--default .select2-selection--single {
			width: 320px;
			height: 52px;
			display: inline-block;
			padding: 0 18px;
			color: #393939;
			font-size: 17px;
			font-weight: 400;
			background-color: rgba(255,255,255,.2);
			border: 0.25px solid rgba(38,41,44,.2);
			border-radius: 3px;
			line-height: 50px;
			cursor: pointer;
			background: url("../img/index/select_arrow.png") no-repeat 95% 50%;
			text-align: left;
			&:active, &:focus {
				background: red url("../img/index/select_arrow_active.png") no-repeat 95% 50%;
				color: #fff; }
			&:not(:last-child) {
				margin-right: 45px; }
			option {
				background-color: #fff;
				color: #393939;
				&:hover {
					background-color: #fff; } } }
		button {
			color: #fefefe;
			font-size: 17px;
			border-radius: 70px;
			font-weight: 500;
			text-transform: uppercase;
			background-color: #ff1d24;
			border: none;
			padding: 15px 42px;
			margin-top: 48px;
			transition: box-shadow .3s ease;
			&:hover {
				box-shadow: 0 0 10px #000; } } } }
.select2-search__field {
	display: none; }
.select2-results__options {
	width: 310px;
	background: #fff;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.26);
	margin-left: 5px;
	margin-top: 16px;
	padding: 15px 23px 10px 23px;
	li:not(:last-child) {
		margin-bottom: 5px; }
	li {
		cursor: pointer;
		font-size: 17px;
		color: #393939;
		font-weight: 400;
		transition: color .3s ease;
		&:hover {
			color: #c91118;
			font-weight: 500; } } }
.partner_wrapper {
	padding-top: 110px;
	.partners_elem:first-child {
		margin-top: 53px; }
	.partners_elem:nth-child(2) {
		margin-top: 59px; }
	.partners_elem:nth-child(4) {
		margin-top: 44px; }
	.partners_elem:nth-child(5) {
		margin-top: 36px; }
	.partners_elem:nth-child(6) {
		margin-top: 28px; }
	.partners img {
		width: 100%;
		height: 100%; }
	background-image: url("../img/index/bg_lines.png") no-repeat; }
.featured_franchise {
	margin-top: 80px;
	margin-left: -15px;
	margin-right: -15px;
	h2 {
		color: #26272d;
		font-size: 37px;
		font-weight: 400;
		margin-left: 23px;
		margin-bottom: 58px; } }
.more_info_button {
	background-color: #cc1118;
	display: inline-block;
	color: #fff;
	font-size: 20px;
	font-weight: 300;
	text-transform: uppercase;
	padding: 10px 35px;
	padding-top: 11px;
	padding-bottom: 9px;
	margin-left: 25px;
	margin-top: 35px;
	transition: box-shadow .3s ease;
	&:hover {
		box-shadow: 0 0 10px #000;
		color: #fff; }
	img {
		margin-right: 25px;
		position: relative;
		top: -2px; } }

.listing_wrapper {
	margin-top: 129px;
	h3 {
		color: #26272d;
		font-size: 35px;
		font-weight: 400;
		margin-left: 8px; }
	ul {
		border-right: 1px solid rgba(0,0,0,.16);
		margin-right: 20px; }
	li {
		margin-bottom: 10px;
		a {
			color: #6c6d6f;
			font-size: 16px;
			font-weight: 300;
			transition: color .3s ease, padding .3s ease;
			&:hover {
				color: #ff1d24;
				font-weight: 500;
				padding-left: 25px;
				background: url("../img/index/listing_arrow.png") no-repeat 0 50%; } } }
	.col-md-3:last-child ul {
		border: none; }
	.listing_info {
		margin-top: 45px;
		margin-bottom: 110px; } }

.featured_franchise_elem {
	background-color: #fff;
	border: 0.22px solid #d7d7d7;
	box-shadow: inset 0 0 36.8px 3.2px rgba(0, 0, 0, 0.08);
	height: 170px;
	position: relative;
	margin: 0 15px;
	margin-bottom: 30px;
	.img_wrapper {
		height: auto;
		text-align: center;
		img {
			margin-top: 20%; } }
	&:hover {
		border: none;
		.featured_franchise_elem_info {
			display: inline-block; } }
	.featured_franchise_elem_info {
		display: none;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.9);
		padding: 8px 20px;
		h4 {
			color: #fff;
			font-size: 23px;
			font-weight: 400; }
		p {
			color: #fff;
			font-size: 15px;
			font-weight: 100;
			line-height: 23px; }
		span {
			display: inline-block;
			width: 100%;
			height: auto;
			position: absolute;
			bottom: 0;
			cursor: pointer;
			left: 0;
			background-color: #ef1c22;
			color: #fff;
			font-size: 17px;
			font-weight: 400;
			padding: 2px 15px;
			img {
				margin-right: 14px;
				margin-top: -4px; } } } }

.featured_video {
	background-color: #f01c22;
	margin-top: 60px;
	padding-top: 100px;
	padding-bottom: 80px;
	margin-bottom: 66px;
	.logo {
		width: 45%;
		height: 88px;
		background-color: #fff;
		box-shadow: 2.5px 4.3px 5px rgba(0, 0, 0, 0.2);
		position: absolute;
		top: -44px;
		z-index: 2;
		img {
			width: 100%;
			height: auto; } }
	.col-md-3:first-child .logo, .col-md-3:nth-child(2) .logo, .col-md-3:last-child .logo {
		padding-top: 9%; }
	.video {
		position: relative;
		img {
			width: 100%;
			height: 100%; }
		.video_descr {
			position: absolute;
			bottom: 0;
			padding: 4px 15px;
			width: 100%;
			left: 0;
			right: 0;
			background-color: rgba(0,0,0,.7);
			color: #fff;
			font-size: 15px;
			font-weight: 300; }
		.arrow {
			float: right; } } }


.more_information_block {
	margin-bottom: 65px;
	.col-md-4:nth-child(2) {
		text-align: right; }
	.more_info_button {
		margin: 0; }
	p {
		color: #6c6d6f;
		font-size: 17px;
		font-weight: 300;
		margin-right: 25px; }
	.subscribe_text_wrapper {
		background-color: #ff1d24;
		background-image: linear-gradient(-42deg, #c70e16 0%, #ff2126 100%);
		border-radius: 5px;
		min-height: 266px;
		img {
			position: absolute;
			top: -9px;
			left: -4px;
			width: 142%; }
		.subscribe_text {
			color: #fff;
			line-height: 42px;
			text-transform: uppercase;
			font-size: 21px;
			font-weight: 300;
			display: inline-block;
			margin-top: 17px;
			margin-bottom: 20px;
			padding: 0 15px;
			span {
				font-size: 25px;
				font-weight: 900; } } }
	input {
		position: relative;
		z-index: 2;
		color: #393939;
		font-size: 14px;
		font-weight: 400;
		border: 1px solid rgba(38,41,44,.2);
		border-radius: 25px;
		width: 265px;
		padding: 9px 25px;
		margin-left: 15px;
		&::-webkit-input-placeholder {
			color: #393939; }
		&:-moz-placeholder {
			color: #393939; }
		&::-moz-placeholder {
			color: #393939; }
		&:-ms-input-placeh {
			color: #393939; } }
	button {
		position: relative;
		z-index: 2;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		background-color: #000;
		border-radius: 25px;
		border: none;
		padding: 7px 27px;
		margin-left: 15px;
		margin-top: 35px;
		transition: box-shadow .3s ease;
		&:hover {
			box-shadow: 0 0 10px #fff; } } }

.franchise_news {
	background-color: #fafafa;
	padding-top: 54px;
	padding-bottom: 120px;
	img {
		width: 100%;
		height: auto; }
	h3 {
		color: #26272d;
		font-size: 35px;
		font-weight: 400;
		margin-left: 13px;
		margin-bottom: 75px; }
	h4 {
		margin-top: 22px;
		color: #212121;
		font-size: 19px;
		font-weight: 400; }
	p {
		line-height: 1.6;
		color: #6c6d6f;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 40px;
		height: 72px; }
	span {
		color: #6c6d6f;
		font-size: 10px;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		bottom: 0;
		.icon {
			font-size: 15px;
			opacity: .7;
			position: relative;
			top: 2px; } }
	.view {
		right: 15px;
		.icon {
			top: 1px; } } }

.education_wrapper {
	padding-top: 80px;
	h3 {
		color: #26272d;
		font-size: 35px;
		font-weight: 400;
		margin-left: 13px;
		margin-bottom: 60px; }
	.education_right_bar {
		padding-left: 40px;
		h3 {
			color: #212121;
			font-size: 22px;
			font-weight: 400;
			line-height: 1;
			margin: 0; }
		p {
			color: #6c6d6f;
			font-size: 18px;
			font-weight: 400;
			margin-top: 33px;
			margin-bottom: 65px; }
		.date {
			float: left; }
		.viev .icon {
			top: 1px; }
		.small_article {
			margin-top: 40px;
			h4 {
				color: #212121;
				font-size: 22px;
				font-weight: 400;
				margin-bottom: 5px; }
			p {
				color: #6c6d6f;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.7;
				margin-bottom: 0;
				margin-top: 0; }
			.date {
				float: right; } } }
	.education_left_bar {
		.edu_img {
			width: 100%; }
		h4 {
			color: #212121;
			font-size: 22px;
			font-weight: 400;
			margin-top: 55px;
			margin-bottom: 5px; }
		p {
			color: #6c6d6f;
			font-size: 16px;
			font-weight: 400;
			line-height: 1.7;
			margin-bottom: 0; }
		a {
			margin: 0;
			margin-top: 75px;
			width: 100%; } }
	.date, .view {
		color: #6c6d6f;
		font-size: 10px;
		font-weight: 700;
		text-transform: uppercase;
		float: right;
		.icon {
			font-size: 15px;
			opacity: .7;
			position: relative;
			top: 2px;
			margin-right: 3px; } } }
.reach_your_traget {
	background-color: #fafafa;
	margin-top: 130px;
	position: relative;
	.img_wrapper {
		width: 50%;
		display: inline-block;
		float: left;
		img {
			width: 100%;
			height: auto; } }
	.reach_info {
		padding: 35px 70px;
		width: 50%;
		display: inline-block;
		float: left;
		padding-bottom: 0;
		h3 {
			color: #26272d;
			font-size: 33px;
			font-weight: 400; }
		p {
			color: #26272d;
			font-size: 17px;
			font-weight: 300;
			line-height: 1.6;
			margin-top: 20px;
			margin-bottom: 7px; }
		li {
			color: #26272d;
			font-size: 17px;
			font-weight: 100;
			&:before {
				content: '';
				display: inline-block;
				width: 13px;
				height: 13px;
				margin-right: 30px;
				background: url("../img/index/list-img.png") no-repeat; } }
		.more_info_button {
			display: block;
			width: 100vw;
			margin-left: -70px;
			margin-top: 39px;
			padding-left: 70px;
			position: absolute;
			bottom: 0; } } }
.latest_from_the_blog {
	background-color: #fff;
	.latest_from_blog {
		background-color: #f7f8f9;
		border-radius: 6px;
		padding-bottom: 20px;
		overflow: hidden;
		h4, p , .date, .view {
			padding: 0 15px; }
		.view, .date {
			padding-bottom: 20px; } } }
.main_footer_wrapper {
	background-color: #fff;
	border-top: 4px solid #d4d4d5;
	padding-top: 82px;
	.subscribe {
		color: #26292c;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 300;
		margin-bottom: 20px;
		span {
			font-weight: 900; } }
	form {
		position: relative;
		margin-bottom: 20px;
		display: inline-block;
		input {
			opacity: 0.7;
			color: #393939;
			font-size: 14px;
			font-weight: 400;
			border: 1px solid #26292c;
			border-radius: 25px;
			width: 400px;
			padding: 9px 25px;
			padding-right: 140px;
			&::-webkit-input-placeholder {
				color: #393939; }
			&:-moz-placeholder {
				color: #393939; }
			&::-moz-placeholder {
				color: #393939; }
			&:-ms-input-placeholder {
				color: #393939; } }
		button {
			color: #fff;
			font-size: 16px;
			font-weight: 400;
			background-color: #ff1d24;
			border: none;
			padding: 4px 31px;
			border-radius: 25px;
			position: absolute;
			right: 5px;
			top: 4px;
			transition: box-shadow .3s ease;
			&:hover {
				box-shadow: 0 0 10px #000; } } }
	.promise {
		color: #6c6d6f;
		font-size: 14px;
		font-weight: 400; }
	.soc_buttons {
		float: right;
		display: inline-block;
		li a {
			background-color: #ff1d24;
			width: 30px;
			height: 30px;
			padding-top: 6px;
			i {
				font-size: 18px; } }
		li:last-child {
			margin-right: 0; }
		li:not(:last-child) {
			margin-right: 21px; } }
	.menu_text {
		color: #26272d;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		display: block;
		margin-top: 46px;
		margin-bottom: 10px;
		padding-left: 7px; }
	li {
		color: #6c6d6f;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 6px; }
	.footer_right_block {
		text-align: right; }
	.footer_middle_block {
		padding-left: 54px; }
	.footer_left_block {
		padding-left: 7px; } }
.copyright {
	border-top: 1px solid rgba(38, 41, 44, .05);
	text-align: center;
	margin-bottom: 30px;
	margin-top: 50px;
	.img_wrapper {
		margin: 19px 0; }
	p {
		color: #8c8f94;
		font-size: 14px;
		font-weight: 400; } }



////// FAQ PAGE
.faq {
	background-image: none;
	border: none;
	.header_page_title {
		margin-top: 0;
		color: #000;
		font-size: 19px;
		font-weight: 400;
		text-transform: uppercase;
		border-bottom: 1px solid #000;
		padding-bottom: 20px;
		margin-bottom: 30px; }
	.error_wrapper__bread {
		padding-top: 34px;
		margin-bottom: 37px; }
	.fr_by_industry {
		width: 100%;
		position: relative;
		display: inline-block;
		color: #fff;
		padding: 7px 41px 7px 26px;
		font-size: 17px;
		line-height: 1.5;
		font-weight: 400;
		text-transform: uppercase;
		background-color: #fe1d23;
		cursor: pointer;
		&:not(:first-child) {
			margin-bottom: 12px; }
		i {
			position: absolute;
			top: 24px;
			right: 20px; } }
	a.fr_by_industry {
		margin-bottom: 20px; }
	.fr_by_industry_block {
		li {
			color: #6c6d6f;
			font-size: 16px;
			font-weight: 300;
			background-color: #fff;
			border: 0.22px solid #e8e8e8;
			border-top: none;
			&:hover {
				a, i {
					color: #2d2d2d;
					font-weight: 400; } }
			a {
				display: block;
				padding: 9px 27px;
				padding-right: 18px;
				transition: color .3s ease;
				i {
					float: right;
					color: #b1b1b1;
					position: relative;
					top: 6px;
					transition: color .3s ease; } } } }
	.close_fr_by_industry {
		margin-top: 12px; } }
.faq_header {
	height: 376px;
	background: url("../img/faq/faq_header_bg.png") no-repeat;
	background-size: 100% 100%;
	form {
		margin-top: 64px;
		select {
			display: none; }
		.select2-container--default .select2-selection--single {
			width: 320px;
			height: 52px;
			display: inline-block;
			padding: 0 18px;
			color: #393939;
			font-size: 17px;
			font-weight: 400;
			background-color: rgba(255,255,255,.2);
			border: 0.25px solid rgba(38,41,44,.2);
			border-radius: 3px;
			line-height: 50px;
			cursor: pointer;
			background: url("../img/index/select_arrow.png") no-repeat 95% 50%;
			text-align: left;
			&:active, &:focus {
				background: red url("../img/index/select_arrow_active.png") no-repeat 95% 50%;
				color: #fff; } } } }
.faq_elem {
	background-color: #fff;
	border: 0.22px solid #d9d9d9;
	padding: 7px 0;
	padding-right: 20px;
	cursor: pointer;
	margin-bottom: 13px;
	.plus_faq_elem {
		transition: transform .3s ease;
		display: inline-block;
		float: left;
		margin-left: 35px;
		margin-top: 8px;
		.vertical_line, .horizontal_line {
			width: 3px;
			display: inline-block;
			height: 15px;
			background-color: #ff2a31; }
		.horizontal_line {
			transform: rotate(90deg);
			position: relative;
			left: -7px;
			transition: opacity .3s ease; } }
	.on_plus_faq_elem {
		transform: rotate(270deg);
		margin-top: 3px;
		.horizontal_line {
			opacity: 0; } }
	h4 {
		color: #ff1d24;
		font-size: 19px;
		font-weight: 500;
		margin-left: 70px; }
	p {
		color: #2d2d2d;
		font-size: 15px;
		font-weight: 300;
		padding-left: 30px;
		line-height: 1.5;
		display: none; } }

///// franchise_news

.franchise_news_page {
	.latest_from_blog {
		margin-top: 50px; }
	.franchise_news {
		padding-bottom: 80px; } }
.load_more_faranchise_news_wrapper {
	text-align: center;
	.load_more_faranchise_news {
		color: #302e34;
		font-size: 17px;
		font-weight: 500;
		text-transform: uppercase;
		cursor: pointer;
		&:before, &:after {
			content: '';
			display: inline-block;
			height: 2px;
			width: 30%;
			background-color: #F2F2F2;
			margin-left: 15px;
			position: relative;
			top: -4px; }
		&:before {
			margin-left: 0;
			margin-right: 15px; } } }
.category_franchise_news_wrapper {
	margin-bottom: -150px;
	h3 {
		margin-right: 400px; }
	.category_franchise_news {
		display: inline-block;
		float: right;
		position: relative;
		top: -66px;
		select {
			display: none; }
		.select2-container--default .select2-selection--single {
			width: 320px;
			height: 52px;
			display: inline-block;
			padding: 0 18px;
			color: #393939;
			font-size: 17px;
			font-weight: 400;
			background-color: rgba(255,255,255,.2);
			border: 0.25px solid rgba(38,41,44,.2);
			border-radius: 3px;
			line-height: 50px;
			cursor: pointer;
			background: url("../img/index/select_arrow.png") no-repeat 95% 50%;
			text-align: left;
			&:active, &:focus {
				background: red url("../img/index/select_arrow_active.png") no-repeat 95% 50%;
				color: #fff; } } } }

///// news details

.franchise_news_detail_wrapper {
	.news_title {
		color: #000;
		font-size: 36px;
		font-weight: 400;
		margin-right: 50%;
		line-height: 1.2;
		margin-bottom: 50px; }
	.news_info {
		position: absolute;
		top: 15px;
		right: 0;
		span {
			color: #999;
			font-size: 13px;
			font-weight: 400; } }
	p {
		color: #2d2d2d;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.6;
		margin-bottom: 30px; }
	.news_detail_gallery_wrapper {
		position: relative;
		max-height: 535px;
		overflow: hidden;
		margin-bottom: 50px;
		.news_detail_gallery {
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%; } }
		ul {
			position: absolute;
			bottom: 15px;
			right: 15px;
			li {
				width: 90px;
				height: 90px;
				cursor: pointer;
				display: inline-block;
				float: left;
				margin-left: 15px;
				img {
					height: 100%;
					width: 100%; } } } }
	.warning_block {
		background-color: #ef1c22;
		margin-bottom: 30px;
		p {
			color: #fff;
			font-size: 15px;
			font-style: italic;
			margin: 30px; } }
	.share_block {
		display: inline-block;
		float: right;
		.share_title {
			color: #26272d;
			font-size: 15px;
			margin-left: 40px;
			font-weight: 400;
			margin-bottom: 7px; }
		li {
			display: inline-block;
			float: left;
			margin-left: 20px;
			a {
				color: #fff;
				font-size: 10px;
				font-weight: 400;
				text-transform: uppercase;
				display: inline-block;
				border-radius: 25px;
				padding: 9px 22px;
				background-color: #35bbec;
				transition: box-shadow .3s ease;
				&:hover {
					box-shadow: 0 0 10px #000; }
				i {
					color: #fff;
					font-size: 16px;
					margin-right: 10px;
					position: relative;
					top: 2px; } }
			&:first-child a {
				background-color: #2a66b3; }
			&:last-child a {
				background-color: #0077b5;
				i {
					top: 1px; } } } }

	.comment_block {
		margin-top: 30px;
		color: #000;
		.comment_elem {
			margin-bottom: 30px; }
		.coment_info {
			margin-left: 80px; }
		h4 {
			font-size: 21px;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase;
			margin-bottom: 50px; }
		.img_wrapper {
			width: 43px;
			height: 43px;
			border-radius: 50%;
			overflow: hidden;
			display: inline-block;
			float: left;
			img {
				width: 100%;
				height: 100%; } }
		.name {
			font-size: 15px;
			font-weight: 700;
			font-style: italic;
			margin-top: 10px;
			display: inline-block; }
		.date {
			color: #999;
			font-size: 13px;
			font-weight: 400;
			display: inline-block;
			margin-left: 60px; }
		.reply {
			font-size: 12px;
			font-weight: 700;
			font-style: italic;
			text-transform: uppercase; }
		p {
			font-size: 15px;
			font-weight: 400;
			margin-top: 20px;
			margin-bottom: 10px; } }
	.comment_form {
		margin-top: 60px;
		.comment_form_elem_wrapper {
			margin-bottom: 15px; }
		label {
			color: #000;
			font-size: 13px;
			font-weight: 700;
			text-transform: uppercase;
			display: block;
			margin-left: 2px; }
		input, textarea {
			color: #999;
			font-size: 15px;
			font-weight: 400;
			background-color: #fff;
			border: 0.22px solid #d2d2d2;
			padding: 7px 16px;
			&::-webkit-input-placeholder {
				color: #999; }
			&:-moz-placeholder {
				color: #999; }
			&::-moz-placeholder {
				color: #999; }
			&:-ms-input-placeh {
				color: #999; } }
		input {
			width: 270px; }
		textarea {
			resize: none;
			width: 100%;
			height: 150px; }
		button {
			color: #fff;
			font-size: 16px;
			font-weight: 400;
			border: none;
			background-color: #ff1d24;
			padding: 6px 25px;
			transition: box-shadow .3s ease;
			&:hover {
				box-shadow: 0 0 10px #000; } } } }

///// event calendar
.customize_dropdown_option .select2-results__options {
	width: 250px; }
.event_calendar {
	.calendar_header {
		background-color: #fff;
		border: 0.22px solid #d7d7d8;
		border-radius: 4px;
		color: #393939;
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
		display: block;
		padding: 10px 12px;
		i {
			font-size: 16px;
			opacity: .7;
			margin-right: 15px; } }
	.calendar {
		border-radius: 4px;
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.22);
		margin-top: 14px;
		margin-bottom: 20px;
		td, tr {
			display: inline-block; }
		td {
			border-radius: 2px;
			cursor: pointer;
			transition: background-color .3s ease;
			&:not(:last-child) {
				margin-right: 10px; }
			&:hover {
				color: #fff;
				background-color: #cecece; } }
		tr {
			width: 100%;
			text-align: center; }
		#calendar2 {
			width: 100%;
			font-size: 15px;
			text-align: center;
			thead {
				tr:last-child {
					td {
						text-align: center;
						width: 21px;
						height: 21px;
						line-height: 21px;
						color: #000;
						font-size: 11px;
						font-weight: 400;
						text-transform: uppercase;
						&:hover {
							background-color: #FFF; } } }
				tr:nth-child(1) td:nth-child(2) {
					color: #000;
					font-size: 12px;
					font-weight: 700;
					text-transform: uppercase;
					line-height: 60px;
					margin: 0;
					&:hover {
						background-color: #FFF; } }
				tr:nth-child(1) td:nth-child(1):hover, tr:nth-child(1) td:nth-child(3):hover {
					cursor: pointer; }
				tr:nth-child(1) td:nth-child(1) {
					width: 18px;
					height: 14px;
					float: left;
					margin: 23px 0 0 30px;
					background: url('../img/event_calendar/arrow.png') no-repeat; }
				tr:nth-child(1) td:nth-child(3) {
					width: 18px;
					height: 14px;
					float: right;
					margin: 23px 30px 0 0;
					background: url('../img/event_calendar/arrow.png') no-repeat;
					transform: rotate(180deg); } }
			tbody {
				padding-bottom: 20px;
				display: block;
				tr {
					padding: 0 25px;
					text-align: center; }
				td {
					text-align: center;
					width: 21px;
					height: 21px;
					line-height: 21px;
					color: #000;
					font-size: 11px;
					font-weight: 400; }
				.not-active-day {
					opacity: 0.34;
					color: #ccc;
					font-size: 11px;
					font-weight: 400;
					&:hover {
						background-color: #FFF; } } }
			tbody td.today {
				background: #fe1d23;
				color: #fff; } } }
	.select2:not(:last-child) {
		margin-right: 30px; }
	select {
		display: none; }
	.select2-container--default .select2-selection--single {
		width: 100%;
		height: 52px;
		display: inline-block;
		padding: 0 18px;
		color: #393939;
		font-size: 17px;
		font-weight: 400;
		background-color: rgba(255,255,255,.2);
		border: 0.25px solid rgba(38,41,44,.2);
		border-radius: 3px;
		line-height: 50px;
		cursor: pointer;
		background: url("../img/index/select_arrow.png") no-repeat 95% 50%;
		text-align: left;
		&:active, &:focus {
			background: red url("../img/index/select_arrow_active.png") no-repeat 95% 50%;
			color: #fff; }
		&:not(:last-child) {
			margin-right: 45px; } }
	.header_page_title .month {
		float: right;
		color: #393939;
		font-size: 17px;
		font-weight: 400;
		text-transform: none;
		i {
			position: relative;
			top: -2px;
			margin-left: 20px; } }
	.second_header_page_title {
		margin-top: 40px; }
	.event_calendar_elem {
		background-color: #fff;
		border: 0.22px solid #d9d9d9;
		display: table;
		margin-bottom: 13px;
		.date_time {
			display: table-cell;
			vertical-align: middle;
			border-left: 4px solid #790000;
			background-color: #fe1d23;
			transition: background-color .3s ease;
			text-align: center;
			padding: 0 17px;
			.day {
				color: #fff;
				font-size: 15px;
				font-weight: 400;
				text-transform: uppercase;
				margin-bottom: 0; }
			.date {
				color: #fff;
				font-size: 23px;
				font-weight: 500;
				display: block;
				margin-top: -7px; } }
		.event_calendar_info {
			display: table-cell;
			padding: 15px 25px;
			h4 {
				color: #212121;
				font-size: 22px;
				font-weight: 400;
				line-height: 1; }
			p {
				color: #2d2d2d;
				font-size: 15px;
				font-weight: 300;
				line-height: 1.5; } }
		&:hover .date_time {
			background-color: #790000; } }
	.paggination_block {
		background-color: #f4f4f4;
		border: 0.22px solid #d8d8d8;
		padding: 0 7px;
		padding-left: 20px;
		.counter_pages {
			color: #6c6d6f;
			font-size: 16px;
			font-weight: 300;
			line-height: 3.5; }
		ul {
			display: inline-block;
			float: right;
			margin: 0;
			li {
				display: inline-block;
				float: left;
				position: relative;
				top: 9px;
				a {
					display: inline-block;
					color: #2d2d2d;
					font-size: 14px;
					font-weight: 400;
					width: 48px;
					height: 39px;
					text-align: center;
					background-color: #fff;
					position: relative;
					top: 0px;
					padding-top: 8px; }
				.current_page {
					background-color: #790000;
					color: #fff; }
				.pag_control {
					display: inline-block;
					background: url("../img/event_calendar/pagination_arrow.png") no-repeat center;
					width: 49px;
					height: 39px;
					border-radius: 0 5px 5px 0;
					background-color: #ef1c22;
					cursor: pointer; }
				&:first-child .pag_control {
					transform: rotate(180deg); }
				&:not(:last-child), &:not(:first-child) {
						border-right: 1px solid #f4f4f4; } } } } }

//// privacy

.privacy_elem {
	h4 {
		color: #2d2d2d;
		font-size: 23px;
		font-weight: 400;
		margin-bottom: 5px; }
	h5 {
		color: #ff1d24;
		font-size: 19px;
		font-weight: 500;
		margin-bottom: 9px; }
	p {
		color: #2d2d2d;
		font-size: 15px;
		font-weight: 300;
		margin-bottom: 30px; } }

////// franchise_directory

.franchise_directory {
	padding-bottom: 0;
	.featured_franchise {
		margin-top: 0; }
	.featured_franchise_elem_top {
		width: 100%;
		height: auto;
		position: relative;
		margin-top: 15px;
		margin-bottom: 25px;
		img {
			width: 100%;
			height: 100%; }
		.info_franchise {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 50%;
			background-color: rgba(0,0,0,.75);
			padding: 19px 16px 0 16px;
			.top_button {
				color: #fff;
				font-size: 15px;
				font-weight: 500;
				width: 131px;
				height: 28px;
				display: inline-block;
				padding-left: 10px;
				background: url("../img/franchise_directory/Placeholder.png") no-repeat; }
			h4 {
				color: #fff;
				font-size: 23px;
				font-weight: 400;
				margin-left: 11px;
				margin-top: 24px; }
			p {
				font-size: 15px;
				color: rgb(255, 255, 255);
				font-weight: 300;
				margin-left: 11px; }
			.bottom_button {
				display: inline-block;
				width: 100%;
				height: auto;
				position: absolute;
				bottom: 0;
				cursor: pointer;
				left: 0;
				background-color: #ef1c22;
				color: #fff;
				font-size: 17px;
				font-weight: 400;
				padding: 2px 15px;
				img {
					width: 13px;
					height: 13px;
					margin-right: 14px;
					margin-top: -4px; } } } }
	.featured_franchise_elem {
		height: auto;
		margin-left: 0;
		margin-right: 0;
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.17);
		border: none;
		.img_wrapper {
			padding-bottom: 20%;
			background-color: #eee; }
		.featured_franchise_elem_info {
			position: static;
			display: block;
			position: relative;
			top: 0;
			left: 0;
			background-color: #FFF;
			h4 {
				color: #2d2d2d;
				font-family: Roboto;
				font-size: 23px;
				font-weight: 400; }
			p {
				color: #2d2d2d;
				font-size: 15px;
				font-weight: 100;
				padding-bottom: 35px; }
			span {
				transition: background-color .3s ease; }
			&:hover {
				span {
					background-color: #000; } } } }
	.paggination_featured {
		ul li .current_page {
			background-color: #000; } }
	.subscribe_franchise {
		margin-top: 70px;
		position: relative;
		.img_wrapper {
			width: 100%;
			height: auto;
			img {
				width: 100%;
				height: 100%; } }
		h3 {
			color: #000;
			font-size: 36px;
			font-weight: 700;
			text-transform: uppercase; }
		p {
			color: #424447;
			font-size: 21px;
			font-weight: 300;
			margin-bottom: 25px; }
		input {
			color: #878787;
			font-size: 17px;
			font-weight: 400;
			background-color: #fff;
			border-radius: 5px;
			border: none;
			padding: 11px 28px;
			overflow: hidden;
			width: 500px;
			&::-webkit-input-placeholder {
				color: #393939; }
			&:-moz-placeholder {
				color: #393939; }
			&::-moz-placeholder {
				color: #393939; }
			&:-ms-input-placeh {
				color: #393939; } }
		button {
			color: #fff;
			font-size: 21px;
			font-weight: 700;
			background-color: #ff1d24;
			border-radius: 0 5px 5px 0;
			padding: 7px 25px;
			padding-bottom: 8px;
			border: none;
			position: absolute;
			top: 0;
			right: 0; }
		.subscribe_info {
			position: absolute;
			top: 20%;
			left: 50%; }
		form {
			position: relative;
			display: inline-block; } } }
