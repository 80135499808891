* {
	padding: 0;
	margin: 0;
	outline: none !important; }

body {
	position: relative;
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.75;
	color: #393939;
	font-size: 16px;
	overflow-x: hidden !important;
	min-width: 320px; }

a {
	color: #393939;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #393939; } }

ul {
	list-style: none; }

::-webkit-input-placeholder {
	color: #fff; }

:-moz-placeholder {
	color: #fff; }

::-moz-placeholder {
	color: #fff; }

:-ms-input-placeholder {
	color: #fff; }

.soc_buttons {
	display: inline-block;
	float: left;
	li {
		display: inline-block;
		float: left;
		transition: opacity .3s ease;
		&:hover {
			opacity: .7; }
		&:not(:last-child) {
			margin-right: 20px; }
		&:last-child {
			margin-right: 38px;
			i {
				position: relative;
				top: -1px; } }
		a {
			width: 26px;
			height: 26px;
			background-color: #bdbec0;
			border-radius: 50%;
			display: inline-block;
			text-align: center;
			i {
				font-size: 15px;
				color: #fff; } } } }

.main_header_top_info {
	margin-top: 16px;
	float: right;
	.language {
		display: inline-block;
		float: left;
		margin-top: 9px;
		margin-right: 35px;
		li {
			display: inline-block;
			float: left;
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			color: #ef1c22;
			line-height: 9px;
			cursor: pointer;
			transition: opacity .3s ease;
			&:hover {
				opacity: .7; }
			&:first-child {
				border-right: 1px solid #393939;
				padding-right: 8px;
				margin-right: 8px; } } }
	.search_button {
		display: inline-block;
		float: left;
		margin-right: 35px;
		cursor: pointer; }
	.login {
		font-size: 13px;
		font-weight: 500; } }
.main_header_middle_info {
	margin-top: 20px;
	.logo {
		float: left;
		display: inline-block;
		width: 190px;
		img {
			width: 100%;
			height: 100%; } }
	.naigation, .navigation ul {
		position: relative;
		margin-left: 205px;
		li {
			display: inline-block;
			float: left;
			text-align: center;
			margin-top: 15px;
			&:nth-child(5) a {
				max-width: 125px; }
			&:nth-child(1) a {
				max-width: 67px; }
			&:not(:last-child) {
				border-right: 1px solid #dfdfdf; }
			a {
				font-size: 13px;
				font-weight: 500;
				text-transform: uppercase;
				line-height: 16px;
				padding: 12px 10px;
				padding-bottom: 9px;
				max-width: 105px;
				display: inline-block;
				border-bottom: 3px solid #fff;
				transition: border-bottom-color .3s ease;
				&:hover {
					border-bottom-color: #bc141b; } }
			.active {
				color: #fff;
				background-color: #ff1d24;
				border-bottom: 3px solid #bc141b; } } } }
.search_input {
	input {
		border: none;
		color: #fff;
		font-size: 15px;
		font-weight: 400;
		position: absolute;
		padding: 0;
		width: 0;
		z-index: 2;
		top: 31px;
		margin-left: 14px;
		background-color: #ef1c22; } }

///// 404 PAGE

.error_wrapper {
	background-image: url(../img/404/bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 1000px;
	position: relative;
	border-top: 8px solid #FF1D24;
	margin-top: 20px;
	&__bread {
		padding-top: 55px;
		padding-bottom: 600px;
		a {
			margin-left: 5px;
			&.active {
				color: #FF1D24;
				font-weight: 700;
				margin-right: 5px; } } }
	&__text {
		text-align: center;
		color: #fff;
		h1 {
			font-weight: 700; }
		p {
			font-weight: 300; }
		a {
			display: inline-block;
			color: #fefefe;
			font-size: 17px;
			border-radius: 70px;
			font-weight: 500;
			text-transform: uppercase;
			background-color: #ff1d24;
			border: none;
			padding: 15px 42px;
			margin-top: 25px;
			transition: box-shadow .3s ease;
			&:hover {
				box-shadow: 0 0 10px #000; } } } }


//// Contact us PAGE
.contact_wrapper {
	background-image: url(../img/Contact/bg.png);
	background-size: 100% 100%;
	height: auto;
	padding-bottom: 100px;
	.error_wrapper__bread {
		padding-bottom: 0; }
	&__form1 {
		padding-top: 230px;
		padding-bottom: 230px;
		margin-top: 50px;
		background-color: rgba(255,255,255, .93);
		padding-left: 30px;
		padding-right: 50px;
		float: left;
		width: 45%;
		height: 865px;
		h2 {
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 50px; }
		.block {
			margin-bottom: 20px;
			.title {
				text-transform: uppercase;
				color: #FF1D24;
				font-size: 12px;
				margin-bottom: 10px; }
			p {
				margin: 0;
				font-weight: 700;
				line-height: 1.1; }
			.text {
				margin-bottom: 10px; } } }
	&__form2 {
		background-color: #FF1D24;
		float: left;
		width: 55%;
		height: 865px;
		margin-top: 50px;
		color: #fff;
		padding: 40px 30px 40px 30px;
		::-webkit-input-placeholder {
			color: #A0A0A0; }

		:-moz-placeholder {
			color: #A0A0A0; }

		::-moz-placeholder {
			color: #A0A0A0; }

		:-ms-input-placeholder {
			color: #A0A0A0; }
		h3 {
			text-align: center;
			text-transform: uppercase;
			font-size: 16px;
			margin: 0;
			line-height: 1.4;
			margin-bottom: 55px; }
		label {
			text-transform: uppercase;
			font-size: 13px;
			span {
				font-weight: 300; } }
		.block {
			margin-bottom: 15px;
			&_fill {
				width: 47%;
				input {
					width: 100%;
					color: #000;
					font-size: 14px;
					padding-left: 10px;
					height: 40px;
					border: 1px solid #D2D2D2;
					border-radius: 2px; }
				select {
					width: 100%;
					background-image: url(../img/Contact/select.png);
					background-repeat: no-repeat;
					background-position: 95% 50%;
					color: #A0A0A0;
					padding-left: 10px;
					appearance: none;
					border-radius: 2px;
					height: 40px; }
				&:first-child {
					float: left; }
				&:last-child {
					float: right; } }
			.select {
				padding-top: 18px; } }
		.textarea_block {
			margin-bottom: 60px;
			textarea {
				display: block;
				width: 100%;
				resize: none;
				color: #000;
				padding: 7px 10px;
				height: 125px;
				border-radius: 2px; } }
		.form_button {
			text-align: center;
			button {
				margin-top: 40px;
				display: inline-block;
				color: #fff;
				background-color: #000;
				border-radius: 25px;
				border: none;
				text-transform: uppercase;
				font-weight: 700;
				padding: 7px 25px;
				transition: background-color .3s ease, color .3s ease;
				&:hover {
					background-color: #fff;
					color: #000;
					box-shadow: 0 0 10px #000; } } } } }

//// FORGOT PASSWORD PAGE

.forgot_wrapper {
	background-image: url(../img/Forgot/bg.png);
	height: auto;
	&.franchise_business {
		background-image: none;
		padding-bottom: 170px;
		.error_wrapper__bread {
			padding-bottom: 80px; }
		.partners_wrapper {
			position: relative;
			padding-top: 120px;
			padding-bottom: 110px;
			padding-right: 400px;
			p {
				text-transform: uppercase;
				font-weight: 700;
				font-size: 20px; }
			.img_wrapper {
				position: absolute;
				top: 50%;
				right: 100px;
				margin-top: -100px;
				width: 285px;
				img {
					width: 100%; } } }
		.fran_arabia {
			background-color: #CC1118;
			color: #fff;
			padding: 15px 25px;
			font-weight: 700;
			letter-spacing: 1px;
			margin-bottom: 45px; }
		.business_btn {
			text-align: center;
			button {
				display: inline-block;
				color: #fff;
				background-color: #FF1D24;
				border-radius: 25px;
				border: none;
				text-transform: uppercase;
				background-image: url(../img/Franbusiness/arrow-right.png);
				background-repeat: no-repeat;
				background-position: 20% 50%;
				font-weight: 700;
				width: 220px;
				height: 42px;
				transition: background-position .5s ease, color .3s ease;
				span {
					transition: opacity .3s ease; }
				span {
					position: relative;
					left: 20px; }
				&:hover {
					span {
						opacity: 0; }
					background-position: 49%;
					color: #000;
					box-shadow: 0 0 10px #000; } } }
		.done_list {
			margin-bottom: 130px;
			li {
				padding-left: 45px;
				position: relative;
				margin-bottom: 10px;
				font-weight: 700;
				font-size: 18px;
				&::before {
					content: url(../img/Franbusiness/done.png);
					position: absolute;
					top: 2px;
					left: 0; } } }
		.business_wrapper {
			position: relative;
			box-shadow: 0 0 10px rgba(0,0,0, .2);
			border-radius: 6px 6px 0 0;
			h1 {
				background-color: #FF1D24;
				color: #fff;
				border-radius: 6px 6px 0 0;
				padding: 15px 50px;
				font-size: 30px;
				text-transform: uppercase; }
			.man_img {
				position: absolute;
				top: -99px;
				right: 20px;
				width: 432px;
				img {
					width: 100%; } }
			.hand1_img {
				position: absolute;
				top: 117px;
				right: 355px;
				width: 60px;
				z-index: 2;
				img {
					width: 100%; } }
			.hand2_img {
				position: absolute;
				top: 242px;
				right: 170px;
				width: 52px;
				z-index: 2;
				img {
					width: 100%; } }
			.girltop_img {
				position: relative;
				top: -8px;
				img {
					width: 100%; }
				&__text {
					background-color: #fff;
					position: absolute;
					top: 50%;
					margin-top: -91px;
					right: 8px;
					width: 625px;
					padding: 30px 25px;
					font-weight: 700; } }
			.gray_block {
				background-color: #E6E6E6;
				width: 969px;
				padding: 15px 30px;
				margin-top: 70px;
				position: relative;
				z-index: 1;
				margin-bottom: 93px;
				h3 {
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 700;
					line-height: 1.4; }
				.shield_block {
					box-shadow: 0 0 10px rgba(0,0,0, .2);
					padding: 20px;
					margin-top: 20px;
					position: relative;
					padding-left: 70px;
					border-radius: 8px;
					&__img {
						position: absolute;
						top: 50%;
						left: 25px;
						margin-top: -14px; }
					p {
						text-transform: uppercase;
						font-size: 14px;
						font-weight: 700; } } } } }
	.error_wrapper__bread {
		padding-bottom: 180px; }
	.reg_form {
		width: 770px;
		box-shadow: 0 0 10px #000;
		&.confirm_email {
			padding-top: 50px;
			.img_wrapper {
				margin-bottom: 40px; }
			p {
				text-align: center;
				font-weight: 700;
				font-size: 18px; } }
		.reg_block {
			.login_input {
				width: 47%;
				&:first-child {
					float: left; }
				&:last-child {
					float: right; } } }
		.radiodiv {
			position: relative;
			display: inline-block;
			margin-right: 65px;
			margin-bottom: 65px;
			margin-top: 20px;
			&.first {
				margin-left: 25px; }
			&.second {
				margin-right: 0; } }
		.radio {
			display: none; }
		.radio + label::before {
			content: '';
			background-image: url(../img/Register/passive.png);
			background-repeat: no-repeat;
			display: inline-block;
			vertical-align: middle;
			position: absolute;
			top: 5px;
			left: -15px;
			margin-left: -12px;
			width: 24px;
			height: 24px; }
		.radio:checked + label::before {
			background-image: url(../img/Register/active.png);
			background-repeat: no-repeat; } }
	&__form {
		width: 500px;
		background-color: #fff;
		margin-left: auto;
		margin-right: auto;
		padding-left: 65px;
		padding-right: 65px;
		margin-bottom: 190px;
		padding-bottom: 50px;
		border-radius: 4px;
		::-webkit-input-placeholder {
			color: #A0A0A0; }

		:-moz-placeholder {
			color: #A0A0A0; }

		::-moz-placeholder {
			color: #A0A0A0; }

		:-ms-input-placeholder {
			color: #A0A0A0; }
		h3 {
			text-align: center;
			text-transform: uppercase;
			padding-top: 40px; }
		.img_wrapper {
			text-align: center;
			margin-bottom: 25px; }
		.login_input {
			margin-bottom: 15px;
			label {
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 700; }
			input {
				width: 100%;
				padding-left: 15px;
				padding-right: 15px;
				height: 40px; } }
		.remember_block {
			margin-top: 35px;
			margin-bottom: 60px; }
		input[type="checkbox"] {
			display: none; }
		input[type="checkbox"] + label::before {
			content: '';
			background-color: #FF1D24;
			width: 20px;
			height: 20px;
			border-radius: 4px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			font-weight: 700;
			margin-right: 10px;
			font-size: 14px;
			color: #fff; }
		input[type="checkbox"]:checked + label::before {
			content: '\2713'; }
		.forgot {
			float: right;
			color: #000;
			text-transform: uppercase;
			font-weight: 700;
			text-decoration: underline;
			font-size: 14px;
			position: relative;
			top: 3px; }
		.input {
			width: 100%;
			margin-bottom: 35px;
			position: relative;
			input {
				width: 100%;
				border: 1px solid #D2D2D2;
				height: 40px;
				padding-left: 55px; }
			.logo {
				position: absolute;
				left: 0;
				top: 0;
				width: 45px;
				height: 100%;
				background-color: #D2D2D2;
				background-image: url(../img/Forgot/letter.png);
				background-repeat: no-repeat;
				background-position: center; } }
		.button_wrapper {
			text-align: center;
			button {
				display: inline-block;
				color: #fff;
				background-color: #FF1D24;
				border-radius: 25px;
				border: none;
				text-transform: uppercase;
				font-weight: 700;
				padding: 7px 40px;
				transition: background-color .3s ease, color .3s ease;
				&:hover {
					background-color: #fff;
					color: #000;
					box-shadow: 0 0 10px #000; } } } } }



//// FOOTER START

.main_footer_wrapper2 {
	background-color: #fff;
	border-top: 4px solid #d4d4d5;
	padding-top: 60px;
	.soc_buttons {
		display: block;
		text-align: center;
		float: none;
		li {
			display: inline-block;
			float: none;
			a {
				background-color: #ff1d24;
				width: 30px;
				height: 30px;
				padding-top: 6px;
				i {
					font-size: 18px; } } }
		li:last-child {
			margin-right: 0; }
		li:not(:last-child) {
			margin-right: 21px; } }
	.menu_text {
		color: #26272d;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		display: block;
		margin-top: 25px;
		margin-bottom: 10px;
		padding-left: 7px; }
	li {
		color: #6c6d6f;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 6px; }
	.footer_left_block {
		padding-left: 7px; } }
.copyright {
	border-top: 1px solid rgba(38, 41, 44, .05);
	text-align: center;
	margin-bottom: 15px;
	margin-top: 50px;
	.img_wrapper {
		margin: 19px 0;
		img {
			width: 150px; } }
	p {
		color: #8c8f94;
		font-size: 14px;
		font-weight: 400;
		margin: 0; } }

/////////// MOBILE MNU
.mobile_logo {
	display: none; }
.ham {
	position: absolute;
	top: 20px;
	left: 20px;
	display: none;
	.ham_img {
		cursor: pointer; } }

.dark_bg {
	&.active {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		z-index: 1; } }

.ham_close {
	position: absolute;
	width: 85%;
	left: -85%;
	top: 0;
	background-color: #fff;
	transition: left .3s ease;
	z-index: 2;
	height: 100%;
	::-webkit-input-placeholder {
		color: #B3B3B3; }

	:-moz-placeholder {
		color: #B3B3B3; }

	::-moz-placeholder {
		color: #B3B3B3; }

	:-ms-input-placeholder {
		color: #B3B3B3; }
	.ham_img {
		cursor: pointer;
		padding: 15px 20px;
		border-bottom: 3px solid #EDEDED; }
	ul {
		margin-bottom: 15px;
		li {
			a {
				display: block;
				padding: 15px 20px;
				border-bottom: 1px solid #ededed;
				font-weight: 700;
				transition: background-color .3s ease, color .3s ease;
				&:hover {
					color: #fff;
					background-color: #FF1D24; } } } }
	input {
		width: 100%;
		border: none;
		background-color: #F2F2F2;
		padding-left: 50px;
		padding-top: 15px;
		padding-bottom: 15px;
		background-image: url(../img/indexmobile/search.png);
		background-repeat: no-repeat;
		background-position: 5% 50%; }
	&.active {
		left: 0; } }

